.border-none:before {
  display: none;
}
.border-none:after {
  display: none;
}
.flex-fix {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  -webkit-flex-basis: 0px;
  flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover {
  position: absolute;
  top: 0;
  right: 0;
}
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ct {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.cl {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.clear-fix {
  *zoom: 1;
}
.clear-fix:before,
.clear-fix:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.page {
  width: 100%;
  height: 100%;
}
.page /deep/ .yd-list-theme3 .yd-list-item {
  margin-bottom: 0.3rem;
}
.page /deep/ .yd-list-title {
  margin-top: 0.15rem;
}
.page /deep/ .yd-list-theme1 .yd-list-item:last-child {
  margin-bottom: 1.5rem;
}
.page /deep/ .yd-list-theme1 .yd-list-item {
  width: 47%;
  float: left;
  margin: 0.1rem;
  padding: 0.1rem;
  border-radius: 0.2rem;
  position: relative;
  border: 0px;
  z-index: 0;
  background-color: #fff;
}
.page .delis {
  width: 100%;
  height: 1rem;
  margin-top: 0.1rem;
  line-height: 1rem;
}
.page .bug {
  width: 100%;
  height: 0.4rem;
  line-height: 0.5rem;
  text-align: center;
  margin-top: 0.3rem;
  color: #f44336;
  border-top: 1px solid #eee;
}
.page .nullClass {
  height: 100%;
  width: 100%;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
