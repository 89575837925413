.border-none[data-v-4a246e7e]:before {
  display: none;
}
.border-none[data-v-4a246e7e]:after {
  display: none;
}
.flex-fix[data-v-4a246e7e] {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  flex: 1;
  -ms-flex-preferred-size: 0px;
      flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar[data-v-4a246e7e]::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover[data-v-4a246e7e] {
  position: absolute;
  top: 0;
  right: 0;
}
.center[data-v-4a246e7e] {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ct[data-v-4a246e7e] {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.cl[data-v-4a246e7e] {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.clear-fix[data-v-4a246e7e] {
  *zoom: 1;
}
.clear-fix[data-v-4a246e7e]:before,
.clear-fix[data-v-4a246e7e]:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.page[data-v-4a246e7e] {
  width: 100%;
  height: 100%;
}
.page[data-v-4a246e7e] .yd-list-theme3 .yd-list-item {
  margin-bottom: 0.3rem;
}
.page[data-v-4a246e7e] .yd-list-title {
  margin-top: 0.15rem;
}
.page[data-v-4a246e7e] .yd-list-theme1 .yd-list-item:last-child {
  margin-bottom: 1.5rem;
}
.page[data-v-4a246e7e] .yd-list-theme1 .yd-list-item {
  width: 47%;
  float: left;
  margin: 0.1rem;
  padding: 0.1rem;
  border-radius: 0.2rem;
  position: relative;
  border: 0px;
  z-index: 0;
  background-color: #fff;
}
.page .delis[data-v-4a246e7e] {
  width: 100%;
  height: 1rem;
  margin-top: 0.1rem;
  line-height: 1rem;
}
.page .bug[data-v-4a246e7e] {
  width: 100%;
  height: 0.4rem;
  line-height: 0.5rem;
  text-align: center;
  margin-top: 0.3rem;
  color: #f44336;
  border-top: 1px solid #eee;
}
.page .nullClass[data-v-4a246e7e] {
  height: 100%;
  width: 100%;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
